<template>
  <div class="row mx-0 bg-white">
    <AppModal
      v-if="showCopySuccessModal"
      closeIcon
      title="Copied to clipboard"
      @onClose="handleCloseCopySuccessModal"
    />
    <div class="col-12 px-4 mt-4 mt-lg-6">
      <div class="d-flex flex-column">
        <span class="label-text text-uppercase color-gray">
          Playlist Name
        </span>
        <h3 class="title">{{ playlist.name }}</h3>
      </div>
    </div>
    <div class="col-12 px-4 mt-lg-2">
      <span class="label-text text-uppercase color-gray d-flex mb-lg-3 mb-2">
        Tracklist Display
      </span>
      <div class="row mx-0 mt-0 align-items-center mb-4">
        <h3 class="title mb-0">{{ user.playlistsDisplayingPreference }}</h3>
        <router-link
          :to="{
            name: 'Display'
          }"
          class="label-text color-gray d-flex ml-3"
        >
          (Change Settings)
        </router-link>
      </div>
      <div
        class="track-list-wrapper bg-gray pl-lg-5 pt-lg-5 pb-lg-3 pr-lg-4 px-4 py-4"
        ref="text"
      >
        <simplebar class="track-list-scrolled d-flex flex-column pr-4">
          <span class="label-text mb-4 d-flex color-purple">
            {{ description + "\n\n" }}
          </span>
          <span
            v-if="user.primeArtistName"
            class="label-text d-flex color-purple"
          >
            {{ user.primeArtistName + "\n" }}
          </span>
          <span
            v-if="playlist.datePlayed"
            class="label-text d-flex color-purple"
          >
            {{ momentDate(playlist.datePlayed) + "\n" }}
          </span>
          <span v-if="playlist.venue" class="label-text d-flex color-purple">
            {{ playlist.venue + "\n" }}
          </span>
          <span
            v-if="playlist.city && playlist.country"
            class="label-text d-flex color-purple mb-3"
          >
            {{ `${playlist.city}, ${playlist.country}` + "\n\n" }}
          </span>
          <div
            v-for="(track, index) in playlist.tracks"
            :key="index"
            class="d-flex"
          >
            <div class="d-flex label-text mr-2">{{ index + 1 }}.</div>
            <div
              class="d-flex flex-column mb-3"
              v-if="!playlist.hiddenTracks.find(el => el.id === track.id)"
            >
              <span v-if="track.performers.length" class="label-text d-flex">
                Artist:
                {{ track.performers.map(({ name }) => name).join(" & ") }}
              </span>
              <span v-if="track.remixers.length" class="label-text d-flex">
                Remixer:
                {{ track.remixers.map(({ name }) => name).join(" & ") }}
              </span>
              <span
                v-if="
                  user.playlistsDisplayingPreference === 'Full track info' &&
                    track.name
                "
                class="label-text d-flex"
              >
                Track: {{ track.name + "\n\n" }}
              </span>
            </div>
            <div class="d-flex flex-column mb-3" v-else>
              <span v-if="track.performers.length" class="label-text d-flex">
                Artist: Hidden by DJ
              </span>
              <span v-if="track.remixers.length" class="label-text d-flex">
                Remixer: Hidden by DJ
              </span>
              <span
                v-if="
                  user.playlistsDisplayingPreference === 'Full track info' &&
                    track.name
                "
                class="label-text d-flex"
              >
                Track: Hidden by DJ
              </span>
            </div>
          </div>
        </simplebar>
      </div>
    </div>
    <div
      class="spacer flex-column flex-grow-1 mt-5 py-3 py-lg-0 bg-gray px-4 d-flex align-items-lg-start align-items-center"
    >
      <PrimaryButton
        class="text-decoration-none my-4 my-lg-5 px-4 justify-content-lg-start justify-content-center"
        type="button"
        @click="copyButtonHandler()"
      >
        Copy text
      </PrimaryButton>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import moment from "moment";
import swal from "sweetalert2";
import simplebar from "simplebar-vue";
import "simplebar/dist/simplebar.min.css";
import AppModal from "@/components/AppModal.vue";
import PrimaryButton from "@/components/global/buttons/PrimaryButton.vue";

export default {
  name: "PlaylistSharingTrackList",
  components: {
    PrimaryButton,
    AppModal,
    simplebar
  },
  data() {
    return {
      showCopySuccessModal: false,
      description:
        "Learn how www.aslice.com is building a more equitable music economy."
    };
  },
  computed: {
    ...mapState("playlistSharing", ["playlist"]),
    ...mapState("user", {
      user: "data"
    }),
    isFullTrackInfo() {
      return this.user.playlistsDisplayingPreference === "Full track info";
    }
  },
  methods: {
    handleCloseCopySuccessModal() {
      this.showCopySuccessModal = false;
    },
    momentDate(data) {
      return moment(data, moment.ISO_8601).format("DD/MM/YYYY");
    },

    copyButtonHandler() {
      let reducer = "";
      for (const child of this.$refs.text.children) {
        reducer += child.textContent + "\n";
      }
      try {
        navigator.clipboard.writeText(reducer);
        this.showCopySuccessModal = true;
      } catch (error) {
        swal.fire({
          icon: "error",
          text: error.message
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.bg-white {
  background-color: white;
}

.bg-gray {
  background: #f2f1ef;
}

.mt-lg-6 {
  @media only screen and (min-width: 992px) {
    margin-top: 60px !important;
  }
}

.label-text {
  font-size: 9px;
  line-height: 11px;
  letter-spacing: 0.15em;
}

.title {
  font-size: 24px;
  line-height: 42px;
  color: #442671;
}

.track-list-wrapper {
  max-width: 678px;
  max-height: 377px;

  .label-text {
    font-size: 12px;
    line-height: 16px;
    letter-spacing: normal;
  }
}

.track-list-scrolled {
  max-height: 339px;
}

@media only screen and (min-width: 992px) {
  .track-list-wrapper {
    max-height: 536px;
  }
  .track-list-scrolled {
    max-height: 483px;
  }
}

::v-deep .simplebar-scrollbar:before {
  color: #c4c4c4;
  width: 5px;
  opacity: 0.3;
}

.rounded-button {
  border: none;
  color: #fff;
  background: #442671;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: #442671 !important;
    background: #fff;
  }
}
</style>
